import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import lotteryImage1 from '../assets/image/Lottery-1.png';

const GameListing = () => {
  const baseURLAPI = import.meta.env.VITE_BASE_URL_API;
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const catId = queryParams.get('catId');

  const [games, setGames] = useState([]);
  const [visibleGames, setVisibleGames] = useState(20);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);

  const navigate = useNavigate();
  const userId = Cookies.get('user_id');

  useEffect(() => {
    if (!userId) {
      console.error('Please Login First.');
      navigate('/login');
      return;
    }
    fetchGames();
  }, [id, catId, userId, navigate]);

  const fetchGames = async () => {
    let fetchURL = `${baseURLAPI}Users/get_all_games`;
    if (id) fetchURL += `/${id}`;
    if (catId) fetchURL += `?catId=${catId}`;

    try {
      const response = await axios.get(fetchURL);
      setGames(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching games:', error);
      setLoading(false);
    }
  };

  const loadMoreGames = () => {
    setVisibleGames(prevVisibleGames => prevVisibleGames + 20);
  };

  const handleGameClick = async (game) => {
    if (!game.MerchantID || !game.PageCode) {
      try {
        const response = await axios.get(`${baseURLAPI}Users/get_game/${game.id}`);
        game = { ...game, ...response.data.game };
      } catch (error) {
        console.error('Error fetching game details:', error);
        alert('Unable to join game. Please try again later.');
        return;
      }
    }

    if (!game.id || !game.title || !game.MerchantID || !game.PageCode) {
      console.error('Incomplete game data:', game);
      alert('Unable to join game. Please try again later.');
      return;
    }

    setSelectedGame(game);
    setIsOpen(true);
  };

  const handleConfirm = () => {
    if (!selectedGame) return;

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://pakgame.net/pak_game/index.php/admin_new/apihitpakGame';
    form.target = '_blank';

    const data = {
      user_id: userId,
      game_id: selectedGame.id,
      game_title: selectedGame.title,
      MerchantID: selectedGame.MerchantID,
      PageCode: selectedGame.PageCode
    };

    Object.keys(data).forEach(key => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);

    setIsOpen(false);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const modalStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999
    },
    content: {
      background: "white",
      padding: "20px",
      borderRadius: "8px",
      textAlign: "center",
      zIndex: 9999
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-12 mx-auto bg-color">
          <div className="game-listing-heading">
            <h5>Game Listing</h5>
          </div>
          <div className="container">
            <div className="row">
              {games.slice(0, visibleGames).map(game => (
                <div className="col-4 px-1" key={game.id}>
                  <div className="game-listing" onClick={() => handleGameClick(game)}>
                    <img
                      className='img-fluid'
                      src={game.image || lotteryImage1}
                      alt={game.title}
                    />
                  </div>
                </div>
              ))}
            </div>
            {visibleGames < games.length && (
              <div className="row">
                <div className="col-12 text-center">
                  <button onClick={loadMoreGames} className="btn btn-primary">
                    See More
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isOpen && (
        <div style={modalStyles.overlay}>
          <div style={modalStyles.content}>
            <h2>Join Game</h2>
            <p>Are you sure you want to join the game?</p>
            <div className="d-flex justify-content w-100">
              <button onClick={toggleModal} className="btn border-0 bg-white mx-auto">Cancel</button>
              <button onClick={handleConfirm} className="btn border-0 bg-white text-primary mx-auto">Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameListing;