// src/App.jsx
import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Immediate Imports (loaded eagerly)
import Header from "./common/Header";
import Footer from "./common/Footer";
import GameListing from "./game/GameListing"; // Kept immediate as in original
import Game from "./home/Game"; // Kept immediate as in original

// Lazy Loaded Imports (all components)
const Home = lazy(() => import("./home/Home"));
const WalletHome = lazy(() => import("./wallet/WalletHome"));
const ActivityHome = lazy(() => import("./activity/ActivityHome"));
const DepositHome = lazy(() => import("./deposit/DepositHome"));
const WithdrawHome = lazy(() => import("./withdraw/WithdrawHome"));
const AccountHome = lazy(() => import("./account/AccountHome"));
const AgencyHome = lazy(() => import("./agency/AgencyHome"));
const Login = lazy(() => import("./login/Login"));
const Register = lazy(() => import("./login/Register"));
const Forgot = lazy(() => import("./login/ForgotPassword"));
const CategoriesList = lazy(() => import("./game/CategoriesList"));
const Sendotp = lazy(() => import("./login/Sendotp"));
const UpdateProfile = lazy(() => import("./account/UpdateProfile"));
const FirstRecharge = lazy(() => import("./activity/FirstRecharge"));
const ActivityDetail = lazy(() => import("./activity/ActivityDetail"));
const DailyTasks = lazy(() => import("./activity/DailyTasks"));
const Rebate = lazy(() => import("./activity/Rebate"));
const SuperJackpot = lazy(() => import("./activity/SuperJackpot"));
const RedeemGift = lazy(() => import("./activity/RedeemGift"));
const DailySignIn = lazy(() => import("./activity/DailySignIn"));
const Vip = lazy(() => import("./account/Vip"));
const GameStats = lazy(() => import("./account/GameStats"));
const UserFeedback = lazy(() => import("./account/UserFeedback"));
const SettingsCenter = lazy(() => import("./settings/SettingsCenter"));
const AvatarSelection = lazy(() => import("./settings/AvatarSelection"));
const ChangePassword = lazy(() => import("./settings/ChangePassword"));
const AboutDetail2 = lazy(() => import("./account/AboutDetail2"));
const AboutDetail = lazy(() => import("./account/AboutDetail"));
const About = lazy(() => import("./account/About"));
const Guide = lazy(() => import("./account/Guide"));
const CustomerService = lazy(() => import("./account/CustomerService"));
const SafeBox = lazy(() => import("./account/SafeBox"));
const DepositHistory = lazy(() => import("./deposit/DepositHistory"));
const WithdrawalHistory = lazy(() => import("./withdraw/WithdrawalHistory"));
const TeamReport = lazy(() => import("./agency/TeamReport"));
const MyCommission = lazy(() => import("./agency/MyCommission"));
const PromotionRule = lazy(() => import("./agency/PromotionRule"));
const AgentLine = lazy(() => import("./agency/AgentLine"));
const RebateRatio = lazy(() => import("./agency/RebateRatio"));
const FullProfileUpdate = lazy(() => import("./account/FullProfileUpdate"));
const EWalletPayment1 = lazy(() => import("./payment/EWalletPayment1"));
const EWalletPayment2 = lazy(() => import("./payment/EWalletPayment2"));
const NotificationScreen = lazy(() => import("./notification/Notification"));
const PaymentAgent = lazy(() => import("./deposit/PaymentAgent")); // Added from second version
const BuyAmount = lazy(() => import("./deposit/BuyAmount")); // Added from second version
const BuyNow = lazy(() => import("./deposit/BuyNow")); // Added from second version
//const AjarPay = lazy(() => import("./deposit/AjarPay")); // ✅ Lazy load AjarPay
//const AgencyHome = lazy(() => import("./AgencyHome")); // Ensure AgencyHome is lazy loaded

// Layout Wrapper
const Layout = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

const App = () => {
  return (
    <Router>
      <Suspense fallback={<div className="loading">Loading...</div>}>
        <Routes>
          {/* Core Routes */}
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="/wallet" element={<Layout><WalletHome /></Layout>} />
          <Route path="/activity" element={<Layout><ActivityHome /></Layout>} />
          <Route path="/deposit" element={<Layout><DepositHome /></Layout>} />
          <Route path="/withdraw" element={<Layout><WithdrawHome /></Layout>} />
          <Route path="/account" element={<Layout><AccountHome /></Layout>} />
          <Route path="/agency" element={<Layout><AgencyHome /></Layout>} />
          
          {/* Auth Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/Sendotp" element={<Layout><Sendotp /></Layout>} />
          
          {/* Game Routes */}
          <Route path="/game" element={<Layout><Game /></Layout>} />
          
          <Route path="/GameListing/:id" element={<Layout><GameListing /></Layout>} />
          <Route path="/CategoriesListing" element={<Layout><CategoriesList /></Layout>} />
          <Route path="/GameListing" element={<Layout><GameListing /></Layout>} />
          <Route path="/GameListing/:id" element={<Layout><GameListing /></Layout>} />
          <Route path="/game-listing" element={<Layout><GameListing /></Layout>} /> {/* Added for lowercase */}
          
          {/* Activity Routes */}
          <Route path="/firstrecharge" element={<Layout><FirstRecharge /></Layout>} />
          <Route path="/activitydetail" element={<Layout><ActivityDetail /></Layout>} />
          <Route path="/activitydetail/:id" element={<Layout><ActivityDetail /></Layout>} />
          <Route path="/dailytasks" element={<Layout><DailyTasks /></Layout>} />
          <Route path="/rebate" element={<Layout><Rebate /></Layout>} />
          <Route path="/superJackpot" element={<Layout><SuperJackpot /></Layout>} />
          <Route path="/redeemGift" element={<Layout><RedeemGift /></Layout>} />
          <Route path="/dailySignIn" element={<Layout><DailySignIn /></Layout>} />
          
          {/* Account Routes */}
          <Route path="/vip" element={<Layout><Vip /></Layout>} />
          <Route path="/gameStats" element={<Layout><GameStats /></Layout>} />
          <Route path="/safeBox" element={<Layout><SafeBox /></Layout>} />
          <Route path="/customerService" element={<Layout><CustomerService /></Layout>} />
          <Route path="/guide" element={<Layout><Guide /></Layout>} />
          <Route path="/about" element={<Layout><About /></Layout>} />
          <Route path="/aboutDetail" element={<Layout><AboutDetail /></Layout>} />
          <Route path="/aboutDetail2" element={<Layout><AboutDetail2 /></Layout>} />
          <Route path="/feedback" element={<Layout><UserFeedback /></Layout>} />
          <Route path="/updateProfile" element={<Layout><SettingsCenter /></Layout>} />
          <Route path="/UpdateDetails" element={<Layout><FullProfileUpdate /></Layout>} />
          <Route path="/Avatar" element={<AvatarSelection />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          
          {/* Transaction History */}
          <Route path="/deposithistory" element={<Layout><DepositHistory /></Layout>} />
          <Route path="/withdrawalhistory" element={<Layout><WithdrawalHistory /></Layout>} />
          <Route path="/withdawsblehistory" element={<Layout><WithdrawalHistory /></Layout>} />
          {/* <Route path="/agency" element={<Layout><AgencyHome /></Layout>} />
          <Route path="/ajarpay" element={<Layout><AjarPay /></Layout>} />  ✅ Fix Added */}


          {/* Agency Routes */}
          <Route path="/teamReport" element={<Layout><TeamReport /></Layout>} />
          <Route path="/rebateRatio" element={<Layout><RebateRatio /></Layout>} />
          <Route path="/myCommission" element={<Layout><MyCommission /></Layout>} />
          <Route path="/promotionRule" element={<Layout><PromotionRule /></Layout>} />
          <Route path="/server" element={<Layout><AgentLine /></Layout>} />
          
          {/* Payment Routes */}
          <Route path="/addbank" element={<EWalletPayment1 />} />
          <Route path="/eWalletPayment2" element={<EWalletPayment2 />} />
          <Route path="/payment-agent" element={<PaymentAgent />} />
          <Route path="/buy-amount" element={<Layout><BuyAmount /></Layout>} />
          <Route path="/buy-now" element={<Layout><BuyNow /></Layout>} />
          
          {/* P2P Routes */}
           <Route path="/payment-agent" element={<PaymentAgent />} />
          {/* Notification */}
          <Route path="/notification" element={<Layout><NotificationScreen /></Layout>} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;